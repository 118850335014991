












import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { NuoxiaoerService } from '@/services/nuoxiaoer-service';

@Component({
  components: {}
})
export default class nuoxiaoerIndex extends Vue {
  public form = {}
  public tableData: any[] = [];
  public loading: Boolean = true;
  public articleContentVisible: boolean = false;
  private routeLinks = [
    { title: '手册-文章管理', path: '/documentManagement' },
    { title: '手册-文章关键词', path: '/nuoxiaoer-keywords' },
    { title: '手册-文章目录页', path: '/nuoxiaoer-article-catelog' },
    { title: '手册-banner', path: '/nuoxiaoer-banner' },
    { title: '手册-应用', path: '/nuoxiaoer-application' },
    { title: '手册-业务场景', path: '/nuoxiaoer-scenario' },
    { title: '手册-诺华视界', path: '/nuoxiaoer-mp-video' },
    { title: '通用配置项', path: '/nuoxiaoer-common-config' },
    { title: '一线KPI考核要求', path: '/nuoxiaoer-kpi' },
    { title: '用户行为日志', path: '/nuoxiaoer-user-action' },
  ]

  @Inject(NuoxiaoerService) private nuoxiaoerService!: NuoxiaoerService;

  public async created(): Promise<void> {
  }

  private mounted() {
  }
}
